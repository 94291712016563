import { isUndefined } from '@/shared/utils/lodashFunc'

import Cookies from 'cookies'
import {
  REFRESH_TOKEN_AGE,
  S50_REFRESH_TOKEN_COOKIE,
  S50_TOKEN_COOKIE,
  TOKEN_AGE,
  getGlobalCookieParams,
  getUserCookie,
  setTokenCookie,
  setUserCookie,
} from 'utils/cookies'
import { retrieveServerCookie } from 'utils/cookies'
import { isClient, isDev, isServer } from 'utils/helper'

import { getWebsite } from './websites'

interface IRequestKnowQuery {
  language?: string
  _offset?: number
  _limit?: number
  users?: string
  email?: string
  sport?: number
}

const now = new Date(Date.now())
const resetCookieTokenParams = getGlobalCookieParams({
  expires: now,
  cookieName: S50_TOKEN_COOKIE,
  value: '',
  sameSite: !isDev ? 'None' : 'Lax',
  secure: !isDev,
})
const resetCookieRefreshTokenParams = getGlobalCookieParams({
  expires: now,
  cookieName: S50_REFRESH_TOKEN_COOKIE,
  value: '',
  sameSite: !isDev ? 'None' : 'Lax',
  secure: !isDev,
})

export interface IRequestQuery extends IRequestKnowQuery {
  [key: string]: any
}

interface IRequestBody {
  [key: string]: any
}

interface IRequestOptions {
  root?: string
  endpoint: string
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  headers?: { [key: string]: any }
  query?: IRequestQuery
  body?: IRequestBody
  isPrivate?: boolean
  clubPrefix?: string
  userToken?: string
  retryRefreshToken?: boolean
  context?: any
}

interface IPrepareRequestURL {
  endpoint: string
  query?: IRequestQuery
}

export const EXPIRED_TOKEN = 'expired_token'

export async function request({
  endpoint,
  method,
  headers,
  query,
  body,
  isPrivate,
  clubPrefix,
  userToken,
  retryRefreshToken = true,
  context,
}: IRequestOptions): Promise<Response | any> {
  if (typeof clubPrefix !== 'string' && isDev) {
    console.warn(
      "Request with no clubPrefix (some endpoints don't need an x-website, so this might be ok), clubPrefix: ",
      clubPrefix,
      ', endpoint: ',
      endpoint,
      ', method: ',
      method
    )
  }

  const url = prepareRequestURL({
    endpoint: endpoint,
    query: query,
  })

  let token = userToken || null

  const tokenResponse = await retrieveToken({ context, clubPrefix, providedToken: token, required: isPrivate })
  token = tokenResponse?.token
  const refreshToken = tokenResponse?.refresh_token
  // redirect to home page if no token and isPrivate
  if (token === null && refreshToken === null && isPrivate) {
    setUserCookie(resetCookieTokenParams)
    setUserCookie(resetCookieRefreshTokenParams)
  }

  return fetch(url, {
    method: method || 'GET',
    headers: {
      'Content-Type': 'application/json', //TODO check - if it is a file / FormData do not send this header
      ...(clubPrefix ? { 'X-Website': clubPrefix } : {}),
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...headers,
    },
    body: JSON.stringify(body), //TODO check - if it is a file / FormData do not JSON.stringify
  })
    .then((response) => {
      if (response.status === 204) {
        return {
          code: 204,
        }
      }
      if (response.ok) {
        return response.json()
      }
      throw {
        code: response.status,
        message: response.statusText,
        url: response.url,
      }
    })
    .catch(async (error) => {
      if (error?.code === 401 && refreshToken && retryRefreshToken) {
        setUserCookie(resetCookieTokenParams)
        const response = await getNewToken({ clubPrefix, refreshToken, context })
        return request({
          endpoint,
          method,
          headers,
          query,
          body,
          isPrivate,
          clubPrefix,
          userToken: response?.token,
          retryRefreshToken: false,
        })
      }

      if (isDev) {
        console.error('error: ', error)
        console.error('original query: ', query, 'clubPrefix: ', clubPrefix)
      }
      if (error?.code === 401 && !refreshToken) {
        setUserCookie(resetCookieTokenParams)
      }
      throw error
    })
}

/**
 *
 * @param {Object} context
 * @param {String} clubPrefix
 * @param {String} providedToken
 * @param {Boolean} required
 * @returns {
 *  token: string | null,
 *  refresh_token: string | null
 * }
 */
async function retrieveToken({
  context,
  clubPrefix,
  providedToken = '',
  required = false,
}: {
  context?: any
  clubPrefix: string
  providedToken?: string
  required?: boolean
}) {
  let token = providedToken
  let refreshToken
  if (context) {
    token = providedToken || retrieveServerCookie({ context, key: S50_TOKEN_COOKIE })
    refreshToken = retrieveServerCookie({ context, key: S50_REFRESH_TOKEN_COOKIE })
  } else if (isClient) {
    token = providedToken || (getUserCookie(S50_TOKEN_COOKIE) as string)
    refreshToken = getUserCookie(S50_REFRESH_TOKEN_COOKIE)
  }

  if (!token && !refreshToken && required) {
    return {
      token: null,
      refresh_token: null,
    }
  }
  if (!token && refreshToken && required) {
    return getNewToken({ clubPrefix, refreshToken, context })
  }
  return {
    token,
    refresh_token: refreshToken,
  }
}

/**
 *
 * @param {String} clubPrefix
 * @param {String} refreshToken
 * @param {Object} context
 * @returns {
 *  token: string | null,
 *  refresh_token: string | null
 * }
 */
async function getNewToken({
  clubPrefix,
  refreshToken,
  context,
}: {
  clubPrefix: string
  refreshToken: string
  context?: any
}) {
  try {
    const response = await request({
      endpoint: '/token/refresh',
      method: 'POST',
      retryRefreshToken: false,
      body: {
        refresh_token: refreshToken,
      },
      clubPrefix,
    })

    const website = await getWebsite({ clubPrefix })

    let newToken: string
    let newRefreshToken: string
    if (response) {
      newToken = response.token
      newRefreshToken = response['refresh_token']
    }
    // persists to cookies or request query
    if (context?.query) {
      const cookies = new Cookies(context.req, context.res, { secure: process.env.NODE_ENV !== 'development' })
      // add to context.query so pages getServerSideProps can access
      context.query[S50_TOKEN_COOKIE] = newToken
      context.query[S50_REFRESH_TOKEN_COOKIE] = newRefreshToken

      const cookieParams = {
        maxAge: TOKEN_AGE * 1000,
        overwrite: true,
        httpOnly: false,
        sameSite: process.env.NODE_ENV !== 'development' ? 'None' : 'Lax',
        secure: process.env.NODE_ENV !== 'development',
      }
      const cookieParamsRefreshToken = {
        maxAge: REFRESH_TOKEN_AGE * 1000,
        overwrite: true,
        httpOnly: false,
        sameSite: process.env.NODE_ENV !== 'development' ? 'None' : 'Lax',
        secure: process.env.NODE_ENV !== 'development',
      }
      // update client cookie
      const domain = website.domain_online
        ? website.domain
        : context.req?.headers?.host
          ? context.req?.headers?.host.split('.').slice(-2).join('.')
          : null

      cookies.set(S50_TOKEN_COOKIE, newToken, { ...cookieParams, domain: isDev ? 'clubee.local' : domain })
      cookies.set(S50_REFRESH_TOKEN_COOKIE, newRefreshToken, {
        ...cookieParamsRefreshToken,
        domain: isDev ? 'clubee.local' : domain,
      })
      // delete old cookie keys
      cookies.set(S50_TOKEN_COOKIE, '')
      cookies.set(S50_REFRESH_TOKEN_COOKIE, '')
    }

    if (isClient) {
      setTokenCookie(newToken, newRefreshToken)
    }
    return response
  } catch (error) {
    if (isDev) {
      console.error('error: ', error)
    }
    setUserCookie(resetCookieRefreshTokenParams)

    throw {
      code: 401,
      message: 'Unauthorized',
    }
  }
}

/**
 *
 * @param {String} endpoint
 * @param {Array|null} query
 * @returns {string}
 */
function prepareRequestURL({ endpoint, query }: IPrepareRequestURL): string {
  let apiPublicRootUrl = process.env.NEXT_PUBLIC_API_ROOT
  let url = `${apiPublicRootUrl}${endpoint}`

  // We are in the browser
  if (!isServer) {
    apiPublicRootUrl = process.env.NEXT_PUBLIC_API_ROOT_BROWSER || process.env.NEXT_PUBLIC_API_ROOT

    if (process.env.NEXT_PUBLIC_API_USE_CACHE === 'true') {
      url = `${process.env.NEXT_PUBLIC_API_CACHE_ROOT}${endpoint}`
    } else {
      url = `${apiPublicRootUrl}${endpoint}`
    }

    if (window.location.search.includes('preview=true')) {
      url = `${apiPublicRootUrl}${endpoint}`
    }
  }

  if (isDev) {
    console.log('Api request, url: ', url)
  }

  if (!query || isUndefined(query)) {
    return url
  }

  Object.keys(query).map((param, index) => {
    // NOTE: this syntax is also converting arrays into flat strings
    // of comma separated values
    url += `${index === 0 ? '?' : '&'}${param}=${encodeURIComponent(query[param])}`
  })

  return url
}
