import  { request } from './api'
import { IClub, IClubItem } from '@/shared/models/Club';

export function getClub({ clubPrefix }: { clubPrefix: string|object }): Promise<IClub> {
    const prefix = typeof clubPrefix === 'string' ? clubPrefix : (clubPrefix as { api: string })?.api
    return request({
        endpoint: '/clubs',
        method: 'GET',
        clubPrefix: prefix,
    })
}

// to display all the clubs that the user is in or one of his child accounts is in
export function getUserClubs({ clubPrefix }: { clubPrefix: string|object }): Promise<IClubItem[]> {
    const prefix = typeof clubPrefix === 'string' ? clubPrefix : (clubPrefix as { api: string })?.api
    const endpoint = '/me/clubs'

    return request({
        endpoint: endpoint,
        method: 'GET',
        clubPrefix: prefix,
        isPrivate: true,
    })
}

export function getClubService(clubId: number): Promise<IClub> {
    return request({
        endpoint: `/clubs/${clubId}`,
        method: 'GET',
    })    
}

export function getClubSocialNetworks({ clubPrefix }: { clubPrefix: string|object }): Promise<IClub> {
    const prefix = typeof clubPrefix === 'string' ? clubPrefix : (clubPrefix as { api: string })?.api
    return request({
        endpoint: '/socialnetworks',
        method: 'GET',
        clubPrefix: prefix,
    })
}