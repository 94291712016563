import { IClub, IClubItem } from '@/shared/models/Club'
import { getClubUrl } from '@/shared/utils/getClubUrl'
import { intersection } from '@/shared/utils/lodashFunc'
import AuthContext from 'contexts/auth'
import { useMemo } from 'react'
import { ICookieSettings } from 'utils/cookies'
import { isUserAdmin } from 'utils/users'

interface IAuthProps {
    initialToken: string;
    initialUser: any;
    initialMenu: any;
    initialOnboarding: any;
    children: React.ReactNode;
    club: IClub;
    initialUserConnectedClubs: IClubItem[];
    cookieSettings: ICookieSettings;
}

export enum AppVersion {
    v2 = 'v2',
    v3 = 'v3'
}

const EDIT_EVENT_ROLES = ['ROLE_SUPER_ADMIN', 'ROLE_SUPER', 'ROLE_TEAMMANAGER', 'ROLE_COMMUNICATION', 'ROLE_TEAM', 'ROLE_PAGE']

//TODO rewrite this compoanent - move manu context in separate  file

export default function AuthProvider({
    children,
    initialToken,
    initialUser,
    initialMenu,
    initialOnboarding,
    club,
    initialUserConnectedClubs,
    cookieSettings
}: IAuthProps): React.ReactElement {

    function getOnboardingLink({
        clubURL,
        featureId,
        callbackLink
    }: {
        clubURL: string;
        featureId: number;
        callbackLink: string;
    }): string {

        // check if we are on local
        if (process.env.NEXT_PUBLIC_APP_WEBSITE.includes('clubee.local')) {
            // on local we use the dev API, so links include the clubee
            // domain clubee.dev which we replace with the v3 local domain
            // some admin parts are still in v2, but most js dev have no v2
            // installed anyway so I ignore that case for now
            // v2 will go away anyway too, so maybe no worth
            if (callbackLink.includes('clubee.dev')) {
                const callbackLinkParts = callbackLink.split('clubee.dev')
                callbackLink = process.env.NEXT_PUBLIC_APP_V3_DOMAIN + callbackLinkParts[1]
            }

        }

        return `${clubURL}/onboarding?featureId=${featureId}&callback=${callbackLink}`
    }

    const normalizeCategoryItems = (items, club: IClub) => {

        let clubURL = getClubUrl(club)

        // check if we are on local
        if (process.env.NEXT_PUBLIC_APP_WEBSITE.includes('clubee.local')) {
            // this project is the v4, however onboarding pages
            // are in the v3 frontend, so we need to fix the club
            // url in local
            const clubURLParts = clubURL.split(process.env.NEXT_PUBLIC_APP_WEBSITE)
            clubURL = process.env.NEXT_PUBLIC_APP_V3_DOMAIN + clubURLParts[1]
        }

        return items.map(item => {
            const { link, has_onboarding: hasOnboarding, id } = item
            const isOnboardingLink = hasOnboarding && !initialOnboarding?.includes(id)

            let newLink = link

            if (isOnboardingLink) {
                newLink = getOnboardingLink({
                    clubURL: clubURL,
                    featureId: id,
                    callbackLink: link
                })
            }

            return {
                ...item,
                link: newLink
            }
        })
    }

    const getColumnIndexByCategoryName = (categoryName: string): number => {
        switch (categoryName) {
            case 'club_management':
            case 'website':
                return 0
            case 'planning':
            case 'communication':
                return 1
            default: return 2
        }
    }

    const hasEventEditRights = useMemo(() => intersection(initialUser?.roles ?? [], EDIT_EVENT_ROLES).length > 0 ,[initialUser])
    const menu = useMemo(() => {
        if (!initialMenu || !club) {
            return null
        }
    
        return initialMenu.map((category) => {
            const items = normalizeCategoryItems(category.items, club)

            return {
                ...category,
                items
            }
        }).reduce((state, category) => {
            const columnIndex = getColumnIndexByCategoryName(category.name)
            state.columns[columnIndex].categories.push(category)
            return state
        },
            {
                columns: [{
                    id: 1,
                    categories: []
                }, {
                    id: 2,
                    categories: []
                }, {
                    id: 3,
                    categories: []
                }]
            })
    }, [initialMenu, club, initialOnboarding])

    return(
        <AuthContext.Provider
            value={{
                token: initialToken,
                user: initialUser,
                menu,
                isUserAdmin: isUserAdmin(initialUser),
                onboarding: initialOnboarding,
                hasEventEditRights,
                userConnectedClubs: initialUserConnectedClubs,
                cookieSettings: cookieSettings
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}