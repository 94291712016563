import { createContext, useContext } from 'react'

import { IClub, ISocialNetwork } from '@/shared/models/Club'
import { IWebsite, IWebsiteFont, IWebsiteMenu, IWebsiteMode, IWebsitePage, WebsitePageType } from '@/shared/models/Website';

export interface IClubContextState {
    club: IClub;
    prefix: string;
    website: IWebsite;
    modes: IWebsiteMode[];
    font: IWebsiteFont;
    menu: IWebsiteMenu[];
    pages: IWebsitePage[];
    page: IWebsitePage;
    clubSocialNetworks: ISocialNetwork[];
    getSystemPageSeo: (type: WebsitePageType) => string;
    pathPrefix: string;
    isFlf: boolean;
}

const ClubInitState: IClubContextState = {
    club: null,
    prefix: '',
    website: null,
    modes: null,
    font: null,
    menu: null,
    pages: null,
    page: null,
    getSystemPageSeo: null,
    pathPrefix: '',
    clubSocialNetworks: [],
    isFlf: false
};
  
export const ClubContext = createContext<IClubContextState>(ClubInitState);

export function useClub(): IClubContextState {
    return useContext(ClubContext)
}

export default ClubContext
