
export enum WebsiteColorPalette {
    LIGHT_PALETTE = 'LIGHT',
    HIGH_PALETTE = 'HIGH',
    DARK_PALETTE = 'DARK'
}

export enum WebsiteColorMode {
    M1_MODE = 'M1',
    M2_MODE = 'M2',
    M3_MODE = 'M3',
    M4_MODE = 'M4'
}

export enum WebsiteButtonStyle {
    ROUNDED = 'ROUNDED',
    PILL_SHAPED = 'PILL_SHAPED',
    SQUARE_CORNERS = 'SQUARE_CORNERS',
}

export enum WebsiteLogoPosition {
    LEFT = 'LEFT',
    MIDDLE = 'MIDDLE',
    LEFT_BIG = 'LEFT_BIG',
}

export enum WebsitePageType {
    HOME = 'home',
    CUSTOM_PAGE = 'custom_page',
    ROSTER = 'roster',
    STATISTICS = 'statistics',
    PLAYER = 'player',
    GAME = 'game',
    EVENT = 'event',
    TRAINING = 'training',
    STANDINGS = 'standings',
    CALENDAR = 'calendar',
    SPONSORS = 'sponsors',
    RESULTS = 'results',
    GALLERIES = 'galleries',
    GALLERY = 'gallery',
    VIDEOS = 'videos',
    VIDEO = 'video',
    SINGLE_NEWS = 'single_news',
    NEWS = 'news',
    RESERVATION = 'reservation',
    SHOP = 'shop',
    POSTS = 'posts',
    POST = 'post'
}

export interface IComposedFooterLink {
    isPage: boolean;
    link: string;
    name: string;
}

export interface IFooterLinkPage {
    page: {
        id: number;
    }
}

export interface IFooterLinkUrl {
    url: string;
    name: string;
    id: number;
}

export type IFooterLink = IFooterLinkPage & IFooterLinkUrl

export interface IWebsite {
    id: number;
    title: string;
    subtitle: string;
    domain: string;
    domain_online: boolean;
    domain_internal: string;
    suffix_internal: string;
    displayed: boolean;
    color1: {
      id: number;
    };
    color2: {
      id: number;
    };
    color_palette: WebsiteColorPalette;
    default_color_mode: WebsiteColorMode;
    button_style: WebsiteButtonStyle;
    logo_position: WebsiteLogoPosition;
    navigation_color_mode: WebsiteColorMode;
    footer_content: string;
    font: {
        id: number;
    };
    new_website: boolean;
    displayed_date?: string;
    footer_clubee_branding: boolean;
    request_domain?: string;
    footer_links?: Array<IFooterLink>;
    abbreviation?: string;
    settings?: {
        placeholders?: Record<string, string>;
    }
}

export interface IWebsitePalette {
    type: WebsiteColorPalette;
    color1: string;
    color2: string;
    color3: string;
    color4: string;
    color5: string;
    color6: string;
    color7: string;
}

export interface IWebsiteMode {
    type: WebsiteColorMode;
    background: string;
    text_background: string;
    card: string;
    accent: string;
    text_accent: string;
}

export interface IColorItem {
    id: number;
    hex: string;
    name: string;
    text_hex: string; 
    placeholder?: string;
}

export interface IWebsiteFont {
    id: number;
    file_name: string;
    file_name2: string;
    name: string;
    name2: string;
}

export interface IWebsitePage {
    title: string;
    id: number;
    visibility: 'public' | 'admin' | 'group';
    type: WebsitePageType;
    system: boolean;
    seo: string;
}

export enum IWebsiteMenuType {
    DROP_DOWN = 'drop-down',
    LINK = 'link',
    PAGE = 'page'
}

export interface IWebsiteMenu {
    position: number;
    name: string;
    children: IWebsiteMenu[];
    type: IWebsiteMenuType;
    page?: {
        id: number;
        type: WebsitePageType;
        system: boolean;
    };
    id: number;
    url?: string; //ex.: "archives-27" or id type === link - full url "https://www.clubee.dev/raphael/demo"
    blank: boolean;
}