import { IMenuItem } from '@/shared/models/Menu'

import { request } from './api'

/**
 * Fetches the current user's information.
 *
 * @param {Object} params - The parameters for the request.
 * @param {string|object} params.clubPrefix - The club prefix.
 * @param {string} params.token - The user token.
 * @param {any} params.context - The context for the request.
 * @returns {Promise<any>} A promise that resolves to the user's information.
 */
export function getMe({
  clubPrefix,
  token,
  context,
}: {
  clubPrefix: string | object
  token: string
  context: any
}): Promise<any> {
  const prefix = typeof clubPrefix === 'string' ? clubPrefix : (clubPrefix as { api: string })?.api
  return request({
    endpoint: '/me',
    method: 'GET',
    clubPrefix: prefix,
    userToken: token,
    context,
    isPrivate: true,
  })
}

/**
 * Fetches the menu items.
 *
 * @param {Object} params - The parameters for the request.
 * @param {string|object} params.clubPrefix - The club prefix.
 * @returns {Promise<IMenuItem[]>} A promise that resolves to an array of menu items.
 */
export function getMenu({ clubPrefix }: { clubPrefix: string | object }): Promise<IMenuItem[]> {
  const prefix = typeof clubPrefix === 'string' ? clubPrefix : (clubPrefix as { api: string })?.api
  return request({
    endpoint: '/admin/menu',
    method: 'GET',
    clubPrefix: prefix,
    isPrivate: true,
  })
}

/**
 * Fetches the onboarding items.
 *
 * @param {Object} params - The parameters for the request.
 * @param {string|object} params.clubPrefix - The club prefix.
 * @returns {Promise<IMenuItem[]>} A promise that resolves to an array of onboarding items.
 */
export function getOnboarding({ clubPrefix }: { clubPrefix: string | object }): Promise<IMenuItem[]> {
  const prefix = typeof clubPrefix === 'string' ? clubPrefix : (clubPrefix as { api: string })?.api
  return request({
    endpoint: '/me/onBoarding',
    method: 'GET',
    clubPrefix: prefix,
    isPrivate: true,
  })
}

/**
 * Fetches users by group section.
 *
 * @param {Object} params - The parameters for the request.
 * @param {string} params.sectionId - The section ID.
 * @param {string|object} params.clubPrefix - The club prefix.
 * @returns {Promise<any>} A promise that resolves to the users in the group section, or null if the request fails.
 */
export function getUsersByGroup({ sectionId, clubPrefix }) {
  const prefix = typeof clubPrefix === 'string' ? clubPrefix : (clubPrefix as { api: string })?.api

  return request({
    endpoint: `/group/section/${sectionId}/public`,
    clubPrefix: prefix,
    method: 'GET',
    isPrivate: false,
  }).catch(() => null)
}
