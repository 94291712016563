export interface ICkubPrefix {
  api: string,
  path: string
}

export function getServerClubPrefix(queryPrefix: string, req?: any): ICkubPrefix {
  
  // if you read this code, first let me say I'm sorry, it is undeniably the worst code I ever wrote, at least I left some comments

  let xwebsiteRaw = 'clubee.local'

  // NOTE at build time 'next build' we have empty req.headers
  if (typeof req !== 'undefined') {
    if (typeof req.headers !== 'undefined' && req.headers['x-website']) {
      // on dev and prod where we proxy the calls through apache, we set a custom header x-website
      // x-website is either "clubee.(local|dev|com)" or the domain name if it is a custom domain club
      xwebsiteRaw = req.headers['x-website']
    } else if (typeof req.headers.get !== 'undefined' && req.headers.get('x-website')) {
      // the req.headers.get is a fix for the middleware, where the headers are not an object but a symbol, to get the value of a symbol you use a getter
      xwebsiteRaw = req.headers.get('x-website')
    } else if (typeof req.headers !== 'undefined' && req.headers['x-forwarded-server']) {
      // x-forwarded-host is because on dev and prod the project is behind an apache proxy, so the host would be v4.clubee.(dev|com) so we need to take the forwarded host which is the "real" host
      // x-forwarded-host is not a standard: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-Host
      // on vercel the 'x-forwarded-host' is always undefined, vercel uses instead 'x-forwarded-server'
      xwebsiteRaw = req.headers['x-forwarded-server']
    } else if (typeof req.headers !== 'undefined' && req.headers['host']) {
      xwebsiteRaw = req.headers['host']
    }
  } else if (typeof window !== 'undefined' && typeof window.location !== 'undefined' && window.location.host) {
    xwebsiteRaw = window.location.host
  }
  
  // in case we are on dev or prod we remove the "www.", for local if the domain is app.clubee.local we remove "app."
  const noWwwXwebsite = xwebsiteRaw.replace('www.', '').replace('app.', '').split(':')

  const xwebsite = noWwwXwebsite[0]

  const clubPrefix = (xwebsite === 'localhost' || xwebsite === 'clubee.local' || xwebsite === 'clubee.dev' || xwebsite === 'clubee.com') ? 'clubee' : xwebsite

  if (clubPrefix === 'clubee') {

    // in apache for clubs on the 'clubee.(dev|com)' domain:
    // apache redirects all clubee clubs (clubs with no custom domain) like clubee.(dev|com)/prefix to frontendv4.clubee.(dev|com)/prefix

    // for URLs like clubee.com/CLUB_PREFIX, "api prefix" = CLUB_PREFIX, "path prefix" = CLUB_PREFIX
    return { api: queryPrefix, path: queryPrefix }

  } else {

    // any incoming request goes to apache, apache then uses a proxy to redirect to v4.clubee.(local|dev|com) for custom domains:
    // apache redirects custom domains like tornadoluxembourg.com to v4.clubee.(dev|com)/web

    // for URLs like my-club.com, "api prefix" = domain of the club, in this case = 'my-club.com', "path prefix" = '' (empty, because my-club.com has no prefix in URL)
    return { api: clubPrefix, path: ''}

  }

}

export function buildUrl(prefix: string, url?: string): string {
  const prefixUrl = prefix ? `/${prefix}${url}` : url
  return prefix === process.env.NEXT_PUBLIC_CLUB_PREFIX_FOR_CUSTOM_DOMAIN ?
      url : prefixUrl
}

export function getOriginFromUrl(prefix: string, url: string): string {
    const origin = url.replace(`/${prefix}`, '')
    return origin.length > 0 ? origin : '/'
}
