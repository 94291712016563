import { geCookieSettings } from './cookies'

export const googleAnalyticsHeadScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${process.env.NEXT_PUBLIC_GTM_AUTH}&gtm_preview=env-${process.env.NEXT_PUBLIC_GTM_ENV}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}');`

export function GoogleAnalyticsBody() {
	return (
		<noscript
			dangerouslySetInnerHTML={{
				__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}&gtm_auth=${process.env.NEXT_PUBLIC_GTM_AUTH}&gtm_preview=env-${process.env.NEXT_PUBLIC_GTM_ENV}&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
			}}
		/>
	)
}

export function GoogleAnalyticsHead() {
	return (
		<script
			dangerouslySetInnerHTML={{
				__html: googleAnalyticsHeadScript,
			}}
		/>
	)
}

export const gaEvent = ({
    action,
    label = '-',
    category = '-',
    additionalProps = {}
}) => {
    const googleAnalytics = (window as any).ga
    const cookieSettings = geCookieSettings()

    if (googleAnalytics && cookieSettings?.analytical) {
        const dataLayer = (window as any).dataLayer
        if (dataLayer) {
            const data = {
                event: 'custom.event',
                eventAction: action,
                eventLabel: additionalProps['userRole'] === 'admin' ? 'admin' : label,
                eventActionName: action,
                eventCategory: category,
                ...additionalProps
            }
            dataLayer.push(data);

            if (!Number.isInteger(action) && Number(localStorage.getItem('clubId')) !== 0) {
                // We suppose the action does not contain the ClubID (as it's not a number),
                // se we will add another item to the dataLayer with the ClubID from the localStorage

                const clubId = Number(localStorage.getItem('clubId'));
                data.eventAction = clubId;
                dataLayer.push(data);
            }
        } else {
            console.warn('Datalayer is missing or blocked')
        }
    } else {
        console.warn('Google Analytics is missing or blocked')
    }
}

export const AdManagerHead = ({ club }) => {
    if (!club?.ads_consent) {
        return <meta/>
    }
    return (
        <>
            <link rel="preconnect" href="https://googleads.g.doubleclick.net" />
            <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
        </>
    )
}