import { defineCookieDomain, getUserCookie, S50_LANG_COOKIE, S50_TOKEN_COOKIE, setUserCookie } from './cookies'

export enum Languages {
    EN = 'en',
    LU = 'lu',
    FR = 'fr',
    DE = 'de',
    FI = 'fi',
    NL = 'nl',
    ES = 'es',
}
export const DEFAULT_LANGUAGE = Languages.EN
export const supportedLanguages = Object.values(Languages)

const isLangugeSupported = (lang: string | undefined): boolean => {
    if (typeof lang === 'undefined') {
        return false
    }
  
    return supportedLanguages.some((supportedLanguage) => supportedLanguage === lang)
}

export function detectLanguage({ req, query }: { req?: any; query?: {[key:string]: string | string[]}}): string {
    let userLanguage
    const queryLanguage = query && query.lang as string || ''

    if (isLangugeSupported(queryLanguage)) {
        userLanguage = queryLanguage
    } else {
        if (req) {
            const cookies: any = req && req.cookies || {}
            userLanguage = cookies.language
        } else {
            userLanguage = getUserCookie(S50_LANG_COOKIE)
        }
    
        if (!isLangugeSupported(userLanguage)) {
            userLanguage = DEFAULT_LANGUAGE
        }
    }
    const never = new Date(253402300000000)

    setUserCookie({
        cookieName: S50_LANG_COOKIE,
        value: userLanguage,
        domain: defineCookieDomain(),
        expires: never,
        path: '/',
        sameSite: 'Lax'
    })

    return userLanguage
}
