import { ITheme } from '@/shared/styles/MuiTheme'

import Slide from '@material-ui/core/Slide'
import Snackbar from '@material-ui/core/Snackbar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import MuiAlert from '@material-ui/lab/Alert'

import SnackbarContext, { IShowSnackbarProps } from 'contexts/snackbar'
import React from 'react'

interface ISnackbarProps {
  children: React.ReactNode
}

interface ISnackbarState extends IShowSnackbarProps {
  open: boolean
  autoHideDuration: number
}

const snackbarInitState: ISnackbarState = {
  open: false,
  variant: 'success',
  autoHideDuration: 6000,
  message: '',
}

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction='right' />
}

const xsWidthMargin = 16
const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    minWidth: 320,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: `calc(100% - ${xsWidthMargin * 2}px)`,
    },
  },
}))

/**
 * SnackbarProvider component that provides a context for showing and hiding snackbars.
 *
 * @param {ISnackbarProps} props - The props for the SnackbarProvider component.
 * @param {React.ReactNode} props.children - The child components that will have access to the Snackbar context.
 *
 * @returns {React.ReactElement} The SnackbarProvider component.
 *
 * @component
 *
 * @example
 * ```tsx
 * import SnackbarProvider from 'path/to/SnackbarProvider';
 *
 * function App() {
 *   return (
 *     <SnackbarProvider>
 *       <YourComponent />
 *     </SnackbarProvider>
 *   );
 * }
 * ```
 */
export default function SnackbarProvider({ children }: ISnackbarProps): React.ReactElement {
  const classes = useStyles()
  const [snackbarState, setSnackbarState] = React.useState<ISnackbarState>(snackbarInitState)

  function showSnackbar(props: IShowSnackbarProps) {
    const { autoHideDuration = 3000 } = props
    setSnackbarState({
      ...props,
      open: true,
      autoHideDuration,
    })
  }

  function hideSnackbar() {
    const { onClose } = snackbarState
    onClose?.()
    setSnackbarState(snackbarInitState)
  }

  const { open, autoHideDuration, variant, message } = snackbarState

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
        hideSnackbar,
      }}
    >
      <Snackbar
        onClose={hideSnackbar}
        action={false}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        autoHideDuration={autoHideDuration}
        TransitionComponent={SlideTransition}
      >
        <MuiAlert classes={classes} elevation={6} variant='filled' severity={variant}>
          {message}
        </MuiAlert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  )
}