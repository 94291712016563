import { IntlProvider } from 'react-intl'

import { useTranslations } from 'contexts/translations'

interface ITranslationsProps {
    children: React.ReactNode;
}

export default function Translations({ children }: ITranslationsProps): React.ReactElement {
    const translationsContext = useTranslations()
    const { messages, locale } = translationsContext.translations
    const language = locale === 'lu' ? 'lb' : locale

    return (
        <IntlProvider
            locale={language || 'en'}
            messages={messages}
            defaultLocale='en'
        >
            {children}
        </IntlProvider>
    )
}
