import React, { createContext, useContext } from 'react'

export interface IShowSnackbarProps {
  variant: 'success' | 'error' | 'warning'
  autoHideDuration?: number
  message: React.ReactNode
  onClose?(): void
}

export interface ISnackbarContextState {
  showSnackbar(props: IShowSnackbarProps): void
  hideSnackbar()
}

// no operation function
const noOp = () => {}

const SnackbarContextState: ISnackbarContextState = {
  showSnackbar: noOp,
  hideSnackbar: noOp,
}

export const SnackbarContext = createContext<ISnackbarContextState>(SnackbarContextState)

export function useSnackbar(): ISnackbarContextState {
  return useContext(SnackbarContext)
}

export default SnackbarContext
