const V4_PATH_SUFFIX = 'v4'

export function parseSeoPath(seoPath: string): { id: number; seoTitle: string } {
    if (process.env.NODE_ENV === 'development' && !seoPath) {
      console.error('src/utils/seoPaths: ', seoPath)
    }
    const array = seoPath.split('-')
    const pageLastPart = array.pop()
    const pageId = pageLastPart.replace(V4_PATH_SUFFIX, '')
    const seoTitle = array.join('-')

    if (pageId && !isNaN(parseInt(pageId, 10))) {
        return {
            id: parseInt(pageId, 10),
            seoTitle
        }
    }  
}