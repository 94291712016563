import  { request } from './api'
import { IMessages } from '@/shared/models/Messages'
import { ITranslationsContextState } from 'contexts/translations'

export async function getTranslation(language: string, translationKeys: IMessages): Promise<IMessages> {
    return request({
        endpoint: `/translate?language=${language}`,
        method: 'POST',
        body: translationKeys
    })
}

export async function getTranslationByClub(
    { clubPrefix, translationKeys }: { clubPrefix: string, translationKeys: IMessages }
): Promise<IMessages> {
    return request({
        endpoint: '/translate',
        method: 'POST',
        clubPrefix,
        body: translationKeys
    })
}

export async function extendTranslations(translationsContext: ITranslationsContextState, translationKeys: IMessages): Promise<{[local: string]: IMessages}> {
    const loadedLocales = Object.keys(translationsContext.loadedTranslations)
    const requests = loadedLocales.map((locale) => getTranslation(locale, translationKeys))
    return Promise.all(requests)
        .then(translations => {
            const newKeys = loadedLocales.reduce((state, locale, index) => {
                state[locale] = translations[index]
                return state
            }, {})
    
            translationsContext.extendTranslations(newKeys)
            return newKeys
        })
}