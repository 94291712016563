export function isEmpty(value: unknown): boolean {
    return  value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
}

export function isUndefined(value: unknown): boolean {
    return typeof value === 'undefined'
}

export function intersection(arr1: unknown[], arr2: unknown[] ): unknown[] {
    return arr1.filter(e => arr2.includes(e));
}

export function shuffle<T>(arr: T[]): T[] {
    const copy = [...arr];
    let currentIndex = copy.length,  randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [copy[currentIndex], copy[randomIndex]] = [
        copy[randomIndex], copy[currentIndex]];
    }
    return copy;
}

export function uniqBy(arr, predicate){
    const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];

    return [...arr.reduce((map, item) => {
        const key = (item === null || item === undefined) ?  item : cb(item);
      
        map.has(key) || map.set(key, item);
        return map;
    }, new Map()).values()];
}


export function chunk<T>(array: T[], perChunk: number): T[][] {
    return array.reduce((resultArray, item, index) => { 
      const chunkIndex = Math.floor(index/perChunk)
    
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }
    
      resultArray[chunkIndex].push(item)
    
      return resultArray
    }, [])
}

export function groupBy<T>(array: T[], key: string): {[key in string]: T[]} {
    return array.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
}

export function groupById<T extends { id: number} >(array: T[]): {[key in string]: T} {
    return array.reduce((rv, x) => {
        rv[x.id] = x
        return rv;
      }, {});
}