import { IMessages } from '@/shared/models/Messages';
import { createContext, useContext } from 'react'
import { DEFAULT_LANGUAGE } from 'utils/language';

export interface ITranslationsContextState {
    isTranslationsLoading: boolean;
    loadedTranslations: { [local: string]: IMessages};
    translations: {
        messages: IMessages;
        locale: string;
    };
    detectLanguage: (_locale: string) => Promise<void>;
    extendTranslations: (_keys: { [local: string]: IMessages }) => void;
    timePassedFormatter(time: number | Date | string): string,
    simpleDateFormatter(time: number | Date | string, formatter?: string): string,
    multipleDaysFormatter(from: string, to: string): string
}

const translationsInitState: ITranslationsContextState = {
    isTranslationsLoading: false,
    loadedTranslations: {},
    translations: {
        messages: {},
        locale: DEFAULT_LANGUAGE,
    },
    detectLanguage: () => Promise.resolve(),
    extendTranslations: () => undefined,
    timePassedFormatter: () => '',
    simpleDateFormatter: () => '',
    multipleDaysFormatter: () => ''
};
  
export const TranslationsContext = createContext<ITranslationsContextState>(translationsInitState);

export function useTranslations(): ITranslationsContextState {
    return useContext(TranslationsContext)
}

export default TranslationsContext
