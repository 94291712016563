import ClubContext from 'contexts/club'
import { IClub, ISocialNetwork } from '@/shared/models/Club'
import { IWebsite, IWebsiteFont, IWebsiteMenu, IWebsiteMode, IWebsitePage, WebsitePageType } from '@/shared/models/Website'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { getServerClubPrefix } from 'utils/clubs'
import { FLF_CLUB_ID } from '@/shared/constants'

interface IClubProps {
    initialClub: IClub;
    initialPrefix: string;
    children: React.ReactNode;
    initWebsite: IWebsite;
    initModes: IWebsiteMode[];
    initFont: IWebsiteFont;
    initMenu: IWebsiteMenu[];
    initPages: IWebsitePage[];
    initPage: IWebsitePage;
    clubSocialNetworks: ISocialNetwork[];
}

export default function ClubProvider({ children, initialClub, initialPrefix, initWebsite, initModes, initFont, initMenu, initPages, initPage, clubSocialNetworks }: IClubProps): React.ReactElement {
    //NOTE: getSystemPageSeo method return seo of system page
    // System page seo needs to build links to system page like link to player|roster|event etc. page
    // ex: player system page link /prefix
    // NOTE: event ad game system page
    const { query } = useRouter()
    const memoizedPrefix = useMemo(() => getServerClubPrefix(query.prefix as string), [query.prefix])
    function getSystemPageSeo(type: WebsitePageType): string {
        if([WebsitePageType.TRAINING, WebsitePageType.EVENT].includes(type)) {
            return 'events'
        }
        if(type === WebsitePageType.GAME) {
            return 'games'
        }
        if (type === WebsitePageType.SHOP) {
            return 'shop'
        }
        const systemPage = initPages.find( page => page.type === type && page.system)

        const pageSeo = systemPage?.seo ?? ''
        if([WebsitePageType.GAME, WebsitePageType.EVENT, WebsitePageType.TRAINING].includes(type)) {
            return [pageSeo, type].join('/')
        }
        return pageSeo
    }

    return(
        <ClubContext.Provider
            value={{
                club: initialClub,
                prefix: initialPrefix,
                website: initWebsite,
                modes: initModes,
                font: initFont,
                menu: initMenu,
                pages: initPages,
                page: initPage,
                getSystemPageSeo,
                pathPrefix: memoizedPrefix.path,
                clubSocialNetworks,
                isFlf: initialClub?.id === FLF_CLUB_ID
            }}
        >
            {children}
        </ClubContext.Provider>
    )
}