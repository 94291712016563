import { IWebsite } from '@/shared/models/Website'
import { gaEvent, googleAnalyticsHeadScript } from './googleAnalytics';
import { COOKIE_SETTINGS_NAME, getUserCookie } from './cookies';

const internalHosts = [
    'clubee.local',
    'clubee.dev',
    'clubee.com'
]

function setGAScript(): void {
    const scriptElement = document.createElement('script')
    scriptElement.innerHTML = googleAnalyticsHeadScript
    document.head.appendChild(scriptElement)
}

export async function startCookieBanner(language: string, prefix: string, website: IWebsite): Promise<void> {
    const CookieBanner = (await import('cookie-banner')).default
    CookieBanner.start({
        internalHosts,
        cookieName: 'cookie_preferences',
        tokenCookieName: 'token',
        durationMonths: 12,
        api: {
            cookies: process.env.NEXT_PUBLIC_API_ROOT + '/me',
            translate: process.env.NEXT_PUBLIC_API_ROOT + '/translate',
        },
        geolocation: false,
        language: language,
        privacyPolicyUrl: process.env.NEXT_PUBLIC_APP_WEBSITE + '/privacy-policy',
        legalDisclaimerUrl: process.env.NEXT_PUBLIC_APP_WEBSITE + '/legal-disclaimer',
        cookiesSettingsUrl: prefix && !website?.domain_online ?
            '/' + prefix + '/cookies-settings'
            : '/cookies-settings',
        website: prefix,
        cookieOnSave: (data: { analytical: any }) => {
            setGAScript();
            const dl2 = (window as any).dataLayer;
            const cookieSettings = getUserCookie(COOKIE_SETTINGS_NAME);
            
            if (cookieSettings && dl2) {
                const parsedCookies = JSON.parse(decodeURIComponent(cookieSettings));
                const gaTags = ["analytical", "customization", "marketing", "required"];
                const keys = Object.keys(parsedCookies)
                window.setTimeout(() => {

                    if (data && data.analytical) {
                        dl2.push({
                            anonymizeIp: false
                        });
                    }

                    gaEvent({
                        action: "consent",
                        category: 'consent',
                        additionalProps: {
                            analytics_storage: parsedCookies.analytics ? 'granted' : 'denied',
                            ad_storage: parsedCookies.marketing ? 'granted' : 'denied' ,
                            functionality_storage: parsedCookies.customization ? 'granted' : 'denied'
                        }
                    })
                }, 5000)
            }
        }
        
    })
}