import { IClubItem } from '@/shared/models/Club';
import { createContext, useContext } from 'react'
import { ICookieSettings } from 'utils/cookies';


export interface IAuthContextState {
    token: string;
    user: any;
    menu: any;
    onboarding: any;
    isUserAdmin: boolean;
    hasEventEditRights: boolean
    userConnectedClubs: IClubItem[];
    cookieSettings: ICookieSettings;
}

const AuthInitState: IAuthContextState = {
    token: null,
    user: null,
    menu: null,
    onboarding: null,
    isUserAdmin: false,
    hasEventEditRights: false,
    userConnectedClubs: [],
    cookieSettings: null
};
  
export const AuthContext = createContext<IAuthContextState>(AuthInitState);

export function useAuth(): IAuthContextState {
    return useContext(AuthContext)
}

export default AuthContext
