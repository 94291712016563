import { IClub } from '../models/Club'
import { IImageSource } from '../models/Image'

export function getClubUrl(club: IClub): string {
    
    // club domains can be something like SUB_DOMAIN.DOMAIN.TLD
    // in which case there is no www in front of the domain
    // my hack is to count the dots, if > 2 then there is a subdomain

    // does the club have a custom domain? (like tornadoluxembourg.com)
    // else the club has no own domain an is hosted on clubee.com like
    // clubee.com/raphael
    if (club?.domain_online) {
        if ((club.domain.split('.').length - 1) > 1) {
           return `https://${club.domain}`
        }
        return `https://www.${club.domain}`
    } 
    return `${process.env.NEXT_PUBLIC_APP_WEBSITE}/${club.suffix}`
}

const fallbackBaseUrl = process.env.NEXT_PUBLIC_IMAGE_CDN_URL

// the first 4 images are used for displaying on images on html head metatags
// the last 2 images are used for displaying images on html body 
export function getClubLogos(club: IClub): IImageSource[] {
    const config = [
        {
            dimension: '16x16',
            width: 266,
            ext: 'webp',
            mime: 'image/webp'
        },
        {
            dimension: '16x16',
            width: 266,
            ext: 'png',
            mime: 'image/png'
        },
        {
            width: 415,
            dimension: '96x96',
            ext: 'webp',
            mime: 'image/webp'
        },
        {
            width: 415,
            dimension: '96x96',
            ext: 'png',
            mime: 'image/png'
        },
        {
            width: 1200,
            dimension: '480x480',
            ext: 'webp',
            mime: 'image/webp'
        },
        {
            width: 1200,
            dimension: '480x480',
            ext: 'png',
            mime: 'image/png'
        },
    ]
    return config.map(item => ({
        ...item,
        src: [fallbackBaseUrl, club.id, 'logo', `${item.dimension}_${club.logo_name}.${item.ext}`].join('/'),
    }));
}

export function getClubFallbackImages(club: IClub): IImageSource[] {
    const config = [
        {
            dimension: '266x140',
            width: 266,
            ext: 'webp',
            mime: 'image/webp'
        },
        {
            dimension: '266x140',
            width: 266,
            ext: 'png',
            mime: 'image/png'
        },
        {
            width: 415,
            dimension: '415x218',
            ext: 'webp',
            mime: 'image/webp'
        },
        {
            width: 415,
            dimension: '415x218',
            ext: 'png',
            mime: 'image/png'
        },
        {
            width: 1200,
            dimension: '1200x630',
            ext: 'webp',
            mime: 'image/webp'
        },
        {
            width: 1200,
            dimension: '1200x630',
            ext: 'png',
            mime: 'image/png'
        },
    ];
    return config.map(item => ({
        ...item,
        src: [fallbackBaseUrl, club.id, 'logo', `${item.dimension}_${club.overlay_name}.${item.ext}`].join('/'),
    }))
}