import { IUser } from '@/shared/models/User'
import { isEmpty } from '@/shared/utils/lodashFunc'

const adminRoles = [
    "ROLE_CLUBEE_ADMIN",
    "ROLE_ADMIN",
    "ROLE_LICENSES",
    "ROLE_GROUPS_MEMBERS",
    "ROLE_MATCHES_RANKINGS",
    "ROLE_PAYMENTS",
    "ROLE_WEBSITES",
    "ROLE_FORMS",
    "ROLE_ATTENDANCE_TRACKER",
    "ROLE_CALENDAR",
    "ROLE_COMMUNICATION",
    "ROLE_SPONSORS",
    "ROLE_PLATFORM_SETTINGS",
    "ROLE_BOOKING",
    "ROLE_SHOP",
    "ROLE_TICKETING",
    "ROLE_LICENSES_ORDER",
    "ROLE_MATCHES_RANKINGS_SIGN",
    "ROLE_MATCHES_RANKINGS_PDF",
    "ROLE_PAYMENTS_EDIT",
    "ROLE_WEBSITES_BUILDER",
    "ROLE_WEBSITES_VISITORS",
    "ROLE_FORMS_EDIT",
    "ROLE_FORMS_VIEW",
    "ROLE_ATTENDANCE_TRACKER_EDIT",
    "ROLE_ATTENDANCE_TRACKER_VIEW",
    "ROLE_CALENDAR_EDIT",
    "ROLE_CALENDAR_VIEW",
    "ROLE_COMMUNICATION_SHARE",
    "ROLE_COMMUNICATION_NEWS",
    "ROLE_COMMUNICATION_GALLERIES",
    "ROLE_COMMUNICATION_VIDEOS",
    "ROLE_COMMUNICATION_SOCIAL_NETWORKS",
    "ROLE_SPONSORS_TOOLS",
    "ROLE_SPONSORS_NETWORKS",
    "ROLE_ADMIN_ROLES",
    "ROLE_CREATE_ADMIN",
    "ROLE_PLATFORM_SETTINGS_EDIT",
    "ROLE_CLUBEE_SUBSCRIPTION",
    "ROLE_EMAIL_ACCOUNTS",
    "ROLE_BOOKING_VIEW",
    "ROLE_SHOP_ITEMS_EDIT",
    "ROLE_SHOP_ORDERS_EDIT",
    "ROLE_TICKETING_ATTENDEES",
    "ROLE_TICKETING_SCAN",
    "ROLE_LICENSES_VIEW",
    "ROLE_GROUPS_MEMBERS_EDIT",
    "ROLE_GROUPS_MEMBERS_VIEW",
    "ROLE_MATCHES_RANKINGS_EDIT",
    "ROLE_MATCHES_RANKINGS_VIEW",
    "ROLE_PAYMENTS_VIEW",
    "ROLE_SHOP_ITEMS_VIEW",
    "ROLE_SHOP_ORDERS_VIEW",
    "ROLE_REFEREE_MANAGER",
]


export function isUserAdmin(user: IUser) {
    if (
        user
        && !isEmpty(user)
        && user.roles
        && user.roles.length > 0
        && user.roles.some(role => adminRoles.includes(role))
    ) {
        return true
    }

    return false
} 